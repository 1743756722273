import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Cookiebot } from '@models/classes/cookiebot.class';
import { IPrivacySettings } from '@models/interfaces';

import { TrackingService } from '../tracking/tracking.service';

import { IS_SERVER } from '@core/config';
import { DEFAULT_PRIVACY_SETTINGS } from '@core/config/privacy';

declare global {
  interface Window {
    Cookiebot: Cookiebot;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PrivacyService {
  public privacySettings$: BehaviorSubject<IPrivacySettings> = new BehaviorSubject(DEFAULT_PRIVACY_SETTINGS);
  private isServer = inject(IS_SERVER, { optional: true });
  private trackingService = inject(TrackingService);

  public init(): void {
    if (this.isServer) {
      this.trackingService.buildGtmScripts();
      return;
    }

    this.initEventListeners();
    this.trackingService.checkGatedDownloadsAllowed().finally();
  }

  get cookieBot(): Cookiebot | null {
    return window.Cookiebot ?? null;
  }

  public showCookiebotDialog(): void {
    this.cookieBot?.renew();
  }

  public updateSetting(update: Partial<IPrivacySettings>): void {
    const next = { ...this.privacySettings$.value, ...update };
    const { preferences, statistics, marketing } = next;

    if (this.cookieBot) {
      this.cookieBot.submitCustomConsent(preferences, statistics, marketing);
    } else {
      this.privacySettings$.next(next);
    }
  }

  private initEventListeners(): void {
    window.addEventListener('CookiebotOnAccept', () => {
      this.update().finally();
    });

    window.addEventListener('CookiebotOnDecline', () => {
      this.update().finally();
    });

    window.addEventListener('CookiebotOnConsentReady', () => {
      if (this.cookieBot?.hasResponse) {
        this.update().finally();
        return;
      }

      this.cookieBot?.renew();
    });
  }

  private async update(): Promise<void> {
    const { marketing, statistics, preferences } = this.cookieBot?.consent;
    this.privacySettings$.next({ marketing, statistics, preferences });
  }
}
